<template>
    <main class="container">
        <div class="content">
            <div class="content_head">
                <div class="inner">
                    <div class="breadcrumb">
                        <ul>
                            <li><a href="#" class="ico_home" title="Home"></a></li>
                            <li>고객센터</li>
                            <li>공지사항</li>
                        </ul>
                    </div>
                    <h2 class="page_title">공지사항</h2>
                </div>
            </div>
            <div class="content_body">
                <div class="inner">
                    <div class="board_notice">
                        <div class="tbl_board">
                            <table>
                                <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>제목</th>
                                    <th>등록일</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-if="boardList.length > 0">
                                    <tr v-for="(item, index) in boardList"  @click="goView(item)" :key="'listNotice-'+index">
                                        <td data-td="No">{{item.idx}}</td>
                                        <td data-td="제목"><a href="#">{{item.title}} <span class="ico_attach"></span></a></td>
                                        <td data-td="등록일">{{item.createDate | dateFilter}}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="3" class="content_none">등록된 컨텐츠가 없습니다.</td>
                                    </tr>

                                </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination">
                            <a href="#" v-if="page-1 < -1 " class="prev" @click="goPage(page-1)"><span class="blind">이전 페이지</span></a>

                            <a v-for="(item,index) in  (parseInt((boardCount / size) ) + 1)" :key="'1-'+index" href="#" @click="goPage(item-1)" :class="{ 'current': item == (page +1) }">{{item}}</a>

                            <a href="#" class="next" v-if="(page+1) < (parseInt((boardCount / size))+1)">
                                <span class="blind" @click="goPage(page+1)">다음 페이지</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default {
        name: "listNotice",
        props: {
            forApp: {
                type: Boolean,
                default: false,
            },
            boardInfo: Object,
            noticeList: Array,
            boardList: Array,
            page: Number,
            size: Number,
            boardCount: Number
        },
        data(){
            return {
                // isLogin: this.$session.get('HWSSessions') == null ? false : true
            }

        },
        mounted() {
        },
        methods: {

            goView( _item ) {
                this.goAppBoard( _item.boardKey, _item.idx )
                this.$emit("goBoardOne", _item.idx);
            },
            goWrite(){
                location.href = "/review/"+this.boardInfo.boardKey+"/form"
            },
            goPage(page) {


                this.$emit('changePage', page);

            },

        },
        watch: {
            'page': function (data) {
                console.log(data)
            }
        }
    }
</script>

<style scoped>

</style>