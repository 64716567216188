<template>
    <notice-list :boardInfo="boardInfo"
                 :boardList="boardList"
                 :noticeList="noticeList"
                 :boardCount="boardCount"
                 :size="size"
                 :page="page" @changePage="aa"></notice-list>
</template>

<script>
    import NoticeList from "@/components/boards/lists/listNotice";
    export default {
        name: "notice_list",
        components: {NoticeList},
        data(){
            return {
                boardList  : [],
                noticeList: [],
                boardInfo: {},
                boardCount: 0,
                page: 0,
                count: 0,
                size: 8,
            }
        },
        mounted() {
            this.getBoardList();
        },
        methods:{

            getBoardList() {

                this.$eventBus.$emit("startProgress")

                // this.params.mode = "list";
                var temp = JSON.parse(JSON.stringify(this.$route.params))
                // var temp = JSON.parse(JSON.stringify(this.params))
                temp.size = this.size || 8
                temp.page = this.page || 0
                //

                this.axiosCaller.get(this, this.APIs.BOARDS + "/getBoardList", temp, (res) => {
                    var result = res.data
                    console.log(result, "====>")
                    if (result.status === "ok") {
                        if (result.boardInfoVO.isNologin && !this.isLogin()) {
                            this.requireLogin(this, false)
                        }
                        this.boardCount = result.boardMap.count
                        //this.boardList.push(...result.boardMap.list)
                        this.boardList = result.boardMap.list
                        this.noticeList = result.noticeMap.list
                        this.boardInfo = result.boardInfoVO

                        let to = localStorage.getItem("to")
                        let prevIdx = localStorage.getItem("prevIdx")
                        if (to) {
                            this.$nextTick(() => {window.scroll(0, to)})
                            localStorage.removeItem("to")
                        }
                        if (prevIdx) {
                            this.$nextTick(() => {
                                this.webUtils.scrollTo("#item-" + prevIdx)
                            })
                            localStorage.removeItem("prevIdx")
                        }
                    } else {
                        this.swalUtils.gritter("Error", result.msg, "error")
                    }
                    this.$eventBus.$emit("doneProgress")
                })
            },
            aa(page){
                this.page = page
                console.log(page)

                this.getBoardList();
            }

        }
    }
</script>

<style scoped>

</style>